import { NextPage } from "next";
import { Loading } from "../components/Loading";
import { useUserContext } from "../context/UserContext";
import { Status } from "../subpages/status";

const NotFound: NextPage = () => {
  const [{ status }] = useUserContext();

  if (["init", "loading"].includes(status)) {
    return <Loading />;
  }

  return <Status code={404} />;
};

export default NotFound;
